@file:OptIn(ExperimentalMaterial3WindowSizeClassApi::class)

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import theme.UntitledTheme

@Suppress("unused")
@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun isCompact() = calculateWindowSizeClass().widthSizeClass == WindowWidthSizeClass.Compact

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun isMedium() = calculateWindowSizeClass().widthSizeClass == WindowWidthSizeClass.Medium

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun isExpanded() = calculateWindowSizeClass().widthSizeClass == WindowWidthSizeClass.Expanded

@Composable
fun Modifier.expandedContainer(horizontalPaddingAdjust: Dp = 0.dp) =
    widthIn(max = UntitledTheme.widths.widthXl3).fillMaxWidth()
        .padding(horizontal = UntitledTheme.spacings.containerPaddingDesktop + horizontalPaddingAdjust)

@Composable
fun Modifier.mediumContainer(horizontalPaddingAdjust: Dp = 0.dp) =
    widthIn(max = UntitledTheme.widths.widthMd).fillMaxWidth()
        .padding(horizontal = UntitledTheme.spacings.containerPaddingMobile + horizontalPaddingAdjust)

@Composable
fun Modifier.compactContainer(horizontalPaddingAdjust: Dp = 0.dp) =
    widthIn(max = UntitledTheme.widths.widthSm).fillMaxWidth()
        .padding(horizontal = UntitledTheme.spacings.containerPaddingMobile + horizontalPaddingAdjust)

@Composable
fun Modifier.container(horizontalPaddingAdjust: Dp = 0.dp) = when {
    isExpanded() -> expandedContainer(horizontalPaddingAdjust)
    isMedium() -> mediumContainer(horizontalPaddingAdjust)
    else -> compactContainer(horizontalPaddingAdjust)
}