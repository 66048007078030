package ui.expanded

import androidx.compose.foundation.layout.Column
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import container
import theme.UntitledTheme

@Composable
fun Expanded(
    onFocusEmailInput: () -> Unit,
    onScrollAndFocusEmailInput: () -> Unit,
    emailInputFocusRequester: FocusRequester
) {
    Column(Modifier.container()) {
        Hero(onFocusEmailInput, emailInputFocusRequester)
        Divider(color = UntitledTheme.colors.borderSecondary)
        Features()
        Divider(color = UntitledTheme.colors.borderSecondary)
        CTA(onScrollAndFocusEmailInput)
        Divider(color = UntitledTheme.colors.borderSecondary)
        Footer()
    }
}