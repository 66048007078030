package ui.expanded

import StringsProvider
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import components.Button
import components.ButtonSize
import org.jetbrains.compose.resources.painterResource
import theme.UntitledTheme
import untitled_ui_components.arrow_up
import untitled_ui_components.Res as UntitledRes

@Composable
fun CTA(onScrollAndFocusEmailInput: () -> Unit) {
    Row(
        Modifier.fillMaxWidth().padding(vertical = UntitledTheme.spacings.spacing9Xl).background(
            color = UntitledTheme.colors.bgSecondary, shape = RoundedCornerShape(size = UntitledTheme.radius.radius2Xl)
        ).padding(UntitledTheme.spacings.spacing7Xl), horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Column(verticalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacingXl)) {
            Text(
                StringsProvider.strings.ctaTitle,
                style = UntitledTheme.typography.displaySmSemiBold,
                color = UntitledTheme.colors.textPrimary900
            )
            Text(
                StringsProvider.strings.ctaSubtitle,
                style = UntitledTheme.typography.textXlRegular,
                color = UntitledTheme.colors.textTertiary600
            )
        }
        Button(
            onClick = onScrollAndFocusEmailInput,
            text = StringsProvider.strings.joinWaitlist,
            iconLeft = painterResource(UntitledRes.drawable.arrow_up),
            size = ButtonSize.LG
        )
    }
}