import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.ComposeViewport
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.skiko.wasm.onWasmReady

val firebaseConfig = js(
    """
        ({
          apiKey: "AIzaSyBQCxz9_MxzAjb5Y3-daT3n7G_aXV2KdMo",
          authDomain: "fracker-landing-page.firebaseapp.com",
          projectId: "fracker-landing-page",
          storageBucket: "fracker-landing-page.firebasestorage.app",
          messagingSenderId: "193609210200",
          appId: "1:193609210200:web:e96f0a00ce4d623b046d2d",
          measurementId: "G-M9GPQQHZ65"
        })
    """
)

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    val app = initializeApp(firebaseConfig)
    getAnalytics(app)

    window.onload = {
        onWasmReady {
            ComposeViewport(document.body!!) {
                Root()
            }
        }
    }
}