package domain.usecases

import data.IWaitlistRepository
import data.models.WaitlistEntry
import di.Singleton
import kotlinx.datetime.Clock
import me.tatarka.inject.annotations.Inject

class EmailAlreadyOnWaitlistException : Exception()

class InvalidEmailException : Exception()

interface ICreateWaitlistEntryNow {
    suspend operator fun invoke(email: String): Result<Unit>

}

@Singleton
@Inject
class CreateWaitlistEntryNow(private val waitlistRepository: IWaitlistRepository, private val clock: Clock) :
    ICreateWaitlistEntryNow {

    companion object {
        private val EMAIL_REGEX =
            Regex("^(?=.{1,256})(?=.{1,64}@.{1,255}\$)[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}\$")
    }

    override suspend fun invoke(email: String) = runCatching {
        if (EMAIL_REGEX.matches(email).not()) throw InvalidEmailException()
        val existingEntries = waitlistRepository.get().getOrThrow()
        if (existingEntries.any { it.email == email }) {
            throw EmailAlreadyOnWaitlistException()
        }
        waitlistRepository.add(WaitlistEntry(email, clock.now())).getOrThrow()
    }

}