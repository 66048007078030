import androidx.compose.runtime.*

object StringsProvider {
    val strings: Strings
        @Composable
        get() = LocalStrings.current
}

@Composable
fun StringsProvider(content: @Composable () -> Unit) {

    var strings by remember { mutableStateOf<Strings?>(null) }

    LaunchedEffect(Unit) {
        strings = Strings.instance()
    }

    strings?.let {
        CompositionLocalProvider(
            LocalStrings provides it,
            content = content
        )
    }
}