import androidx.compose.runtime.Composable
import theme.UntitledTheme

@Composable
fun Root() {
    UntitledTheme {
        StringsProvider {
            LandingPane()
        }
    }
}