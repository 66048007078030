import androidx.compose.animation.core.tween
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import ui.expanded.Expanded
import kotlinx.coroutines.launch
import ui.mediumCompact.MediumCompact
import theme.UntitledTheme

@Composable
fun LandingPane() {
    val emailInputFocusRequester = remember { FocusRequester() }
    val coroutineScope = rememberCoroutineScope()
    val scrollState = rememberScrollState()
    val focusEmailInput: () -> Unit = {
        emailInputFocusRequester.requestFocus()
    }
    val scrollAndFocusEmailInput: () -> Unit = {
        coroutineScope.launch {
            scrollState.animateScrollTo(0, tween(durationMillis = 800))
            focusEmailInput()
        }
    }
    Box(
        modifier = Modifier.fillMaxSize().verticalScroll(scrollState).background(UntitledTheme.colors.bgPrimary),
        contentAlignment = Alignment.TopCenter
    ) {
        if (isExpanded()) Expanded(
            focusEmailInput,
            scrollAndFocusEmailInput,
            emailInputFocusRequester
        ) else MediumCompact(
            scrollAndFocusEmailInput, emailInputFocusRequester
        )
    }
}