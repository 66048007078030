package di

import `data`.WaitlistRepository
import domain.usecases.CreateWaitlistEntryNow
import kotlin.reflect.KClass
import me.tatarka.inject.`internal`.LazyMap
import me.tatarka.inject.`internal`.ScopedComponent
import ui.HeroViewModel
import ui.IHeroViewModel

public fun KClass<LandingPageComponent>.create(): LandingPageComponent =
    InjectLandingPageComponent()

public class InjectLandingPageComponent() : LandingPageComponent(), ScopedComponent {
  override val _scoped: LazyMap = LazyMap()

  override val heroViewModel: IHeroViewModel
    get() = _scoped.get("ui.HeroViewModel") {
      HeroViewModel(
        createWaitlistEntryNow = _scoped.get("domain.usecases.CreateWaitlistEntryNow") {
          CreateWaitlistEntryNow(
            waitlistRepository = _scoped.get("`data`.WaitlistRepository") {
              WaitlistRepository(
                firestoreClient = _scoped.get("IFirestoreClient") {
                  jsonParser()
                }
              )
            }.bind,
            clock = _scoped.get("kotlinx.datetime.Clock") {
              clock()
            }
          )
        }.bind,
        viewModelScope = _scoped.get("di.ViewModelScope") {
          viewModelScope()
        }
      )
    }.bind
}
