import androidx.compose.runtime.staticCompositionLocalOf
import frackerlandingpage.utils.generated.resources.*
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import org.jetbrains.compose.resources.getString

val LocalStrings = staticCompositionLocalOf { Strings() }

data class Strings(
    val joinWaitlist: String = "",
    val heroTitle: String = "",
    val heroSubtitle: String = "",
    val enterEmail: String = "",
    val getNotified: String = "",
    val features: String = "",
    val featuresTitle: String = "",
    val featuresSubtitle: String = "",
    val feature1Title: String = "",
    val feature1Subtitle: String = "",
    val feature1Bullet1: String = "",
    val feature1Bullet2: String = "",
    val feature1Bullet3: String = "",
    val feature2Title: String = "",
    val feature2Subtitle: String = "",
    val feature2Bullet1: String = "",
    val feature2Bullet2: String = "",
    val feature2Bullet3: String = "",
    val feature3Title: String = "",
    val feature3Subtitle: String = "",
    val feature3Bullet1: String = "",
    val feature3Bullet2: String = "",
    val feature3Bullet3: String = "",
    val ctaTitle: String = "",
    val ctaSubtitle: String = "",
    val copyright: String = "",
    val joinWaitlistSuccess: String = "",
    val joinWaitlistError: String = "",
    val joinWaitlistAlreadyExists: String = "",
    val joinSuccess: String = "",
    val joinProgress: String = "",
    val joinWaitlistInvalidEmail: String = "",
) {
    companion object {
        suspend fun instance() = coroutineScope {
            Strings(
                joinWaitlist = async { getString(Res.string.join_waitlist) }.await(),
                heroTitle = async { getString(Res.string.hero_title) }.await(),
                heroSubtitle = async { getString(Res.string.hero_subtitle) }.await(),
                enterEmail = async { getString(Res.string.enter_email) }.await(),
                getNotified = async { getString(Res.string.get_notified) }.await(),
                features = async { getString(Res.string.features) }.await(),
                featuresTitle = async { getString(Res.string.features_title) }.await(),
                featuresSubtitle = async { getString(Res.string.features_subtitle) }.await(),
                feature1Title = async { getString(Res.string.feature_1_title) }.await(),
                feature1Subtitle = async { getString(Res.string.feature_1_subtitle) }.await(),
                feature1Bullet1 = async { getString(Res.string.feature_1_bullet_1) }.await(),
                feature1Bullet2 = async { getString(Res.string.feature_1_bullet_2) }.await(),
                feature1Bullet3 = async { getString(Res.string.feature_1_bullet_3) }.await(),
                feature2Title = async { getString(Res.string.feature_2_title) }.await(),
                feature2Subtitle = async { getString(Res.string.feature_2_subtitle) }.await(),
                feature2Bullet1 = async { getString(Res.string.feature_2_bullet_1) }.await(),
                feature2Bullet2 = async { getString(Res.string.feature_2_bullet_2) }.await(),
                feature2Bullet3 = async { getString(Res.string.feature_2_bullet_3) }.await(),
                feature3Title = async { getString(Res.string.feature_3_title) }.await(),
                feature3Subtitle = async { getString(Res.string.feature_3_subtitle) }.await(),
                feature3Bullet1 = async { getString(Res.string.feature_3_bullet_1) }.await(),
                feature3Bullet2 = async { getString(Res.string.feature_3_bullet_2) }.await(),
                feature3Bullet3 = async { getString(Res.string.feature_3_bullet_3) }.await(),
                ctaTitle = async { getString(Res.string.cta_title) }.await(),
                ctaSubtitle = async { getString(Res.string.cta_subtitle) }.await(),
                copyright = async { getString(Res.string.copyright) }.await(),
                joinWaitlistSuccess = async { getString(Res.string.join_waitlist_success) }.await(),
                joinWaitlistError = async { getString(Res.string.join_waitlist_error) }.await(),
                joinWaitlistAlreadyExists = async { getString(Res.string.join_waitlist_already_exists) }.await(),
                joinSuccess = async { getString(Res.string.join_success) }.await(),
                joinProgress = async { getString(Res.string.join_progress) }.await(),
                joinWaitlistInvalidEmail = async { getString(Res.string.join_waitlist_invalid_email) }.await(),
            )
        }

    }
}