package ui

import BaseViewModel
import IBaseViewModel
import androidx.lifecycle.viewModelScope
import di.Singleton
import di.ViewModelScope
import domain.usecases.EmailAlreadyOnWaitlistException
import domain.usecases.ICreateWaitlistEntryNow
import domain.usecases.InvalidEmailException
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject

enum class JoinWaitlistError {
    AlreadyJoined, EmailInvalid, Generic
}

data class HeroState(
    val email: String = "",
    val error: JoinWaitlistError? = null,
    val loading: Boolean = false,
    val success: Boolean = false
)

interface IHeroViewModel : IBaseViewModel<HeroState> {

    fun setEmail(email: String)

    fun send()

}

@Singleton
@Inject
class HeroViewModel(
    private val createWaitlistEntryNow: ICreateWaitlistEntryNow,
    viewModelScope: ViewModelScope,
    initialState: HeroState = HeroState()
) : BaseViewModel<HeroState>(initialState, viewModelScope), IHeroViewModel {

    override fun setEmail(email: String) {
        transformState { it.copy(email = email, error = null) }
    }

    override fun send() {
        viewModelScope.launch {
            transformState { it.copy(loading = true) }
            createWaitlistEntryNow(state.email).onFailure { throwable ->
                transformState {
                    it.copy(
                        error = when (throwable) {
                            is EmailAlreadyOnWaitlistException -> JoinWaitlistError.AlreadyJoined
                            is InvalidEmailException -> JoinWaitlistError.EmailInvalid
                            else -> JoinWaitlistError.Generic
                        }
                    )
                }
            }.onSuccess {
                transformState { it.copy(success = true) }
            }
            transformState { it.copy(loading = false) }
        }
    }

}
