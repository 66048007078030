package ui.expanded

import StringsProvider
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import components.Button
import components.ButtonSize
import components.InputField
import di.landingPageComponent
import frackerlandingpage.features.landingpage.generated.resources.Res
import frackerlandingpage.features.landingpage.generated.resources.logo
import frackerlandingpage.features.landingpage.generated.resources.tracker
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.vectorResource
import theme.UntitledTheme
import ui.JoinWaitlistError
import untitled_ui_components.check_circle_broken
import untitled_ui_components.Res as UntitledRes

@Composable
fun Hero(onFocusEmailInput: () -> Unit, emailInputFocusRequester: FocusRequester) {
    Column {
        Header(onFocusEmailInput)
        Card(emailInputFocusRequester)
    }
}

@Composable
private fun Header(onFocusEmailInput: () -> Unit) {
    Row(
        Modifier.fillMaxWidth().height(80.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(vectorResource(Res.drawable.logo), contentDescription = null)
        Button(onClick = onFocusEmailInput, text = StringsProvider.strings.joinWaitlist, size = ButtonSize.LG)
    }
}

@Composable
private fun Card(emailInputFocusRequester: FocusRequester) {
    val viewModel = remember { landingPageComponent.heroViewModel }
    val state by viewModel.stateFlow.collectAsState()
    Row(
        Modifier.fillMaxWidth()
            .padding(top = UntitledTheme.spacings.spacing4Xl, bottom = UntitledTheme.spacings.spacing9Xl)
            .height(UntitledTheme.widths.widthLg).background(
                color = UntitledTheme.colors.bgBrandSection,
                shape = RoundedCornerShape(size = UntitledTheme.radius.radius4Xl)
            ).padding(horizontal = UntitledTheme.spacings.spacing5Xl),
        horizontalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacing7Xl),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Column(Modifier.width(560.dp).padding(start = UntitledTheme.spacings.spacing4Xl)) {
            Text(
                StringsProvider.strings.heroTitle,
                style = UntitledTheme.typography.display2XlSemiBold,
                color = UntitledTheme.colors.textPrimaryOnBrand
            )
            Spacer(Modifier.height(UntitledTheme.spacings.spacing3Xl))
            Text(
                StringsProvider.strings.heroSubtitle,
                modifier = Modifier.widthIn(max = UntitledTheme.widths.widthSm),
                style = UntitledTheme.typography.textXlRegular,
                color = UntitledTheme.colors.textTertiaryOnBrand
            )
            Spacer(Modifier.height(UntitledTheme.spacings.spacing6Xl))
            Row(
                Modifier.width(UntitledTheme.widths.widthSm),
                horizontalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacingXl)
            ) {
                InputField(
                    modifier = Modifier.weight(1f).focusRequester(emailInputFocusRequester),
                    value = state.email,
                    onValueChange = viewModel::setEmail,
                    hint = when {
                        state.success -> StringsProvider.strings.joinWaitlistSuccess
                        state.error == JoinWaitlistError.Generic -> StringsProvider.strings.joinWaitlistError
                        state.error == JoinWaitlistError.AlreadyJoined -> StringsProvider.strings.joinWaitlistAlreadyExists
                        state.error == JoinWaitlistError.EmailInvalid -> StringsProvider.strings.joinWaitlistInvalidEmail
                        else -> StringsProvider.strings.getNotified
                    },
                    onEnter = viewModel::send,
                    destructive = state.error != null,
                    placeholder = StringsProvider.strings.enterEmail,
                    enabled = state.loading.not() && state.success.not()
                )
                Button(
                    onClick = viewModel::send,
                    text = when {
                        state.loading -> StringsProvider.strings.joinProgress
                        state.success -> StringsProvider.strings.joinSuccess
                        else -> StringsProvider.strings.joinWaitlist
                    },
                    size = ButtonSize.LG,
                    iconLeft = painterResource(UntitledRes.drawable.check_circle_broken).takeIf { state.success },
                    enabled = state.loading.not() && state.success.not()
                )
            }
        }
        val borderWidth = 8.dp
        Box {
            Image(
                painter = painterResource(Res.drawable.tracker),
                contentScale = ContentScale.FillHeight,
                modifier = Modifier.wrapContentWidth(align = Alignment.Start, unbounded = true).border(
                    width = borderWidth,
                    color = UntitledTheme.colors.white.copy(alpha = 0.5f),
                    shape = RoundedCornerShape(size = UntitledTheme.radius.radiusLg + borderWidth)
                ).padding(borderWidth).height(512.dp).clip(RoundedCornerShape(size = UntitledTheme.radius.radiusLg)),
                contentDescription = null
            )
        }
    }
}