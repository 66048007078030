@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package frackerlandingpage.utils.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val copyright: StringResource by 
      lazy { init_copyright() }

  public val cta_subtitle: StringResource by 
      lazy { init_cta_subtitle() }

  public val cta_title: StringResource by 
      lazy { init_cta_title() }

  public val enter_email: StringResource by 
      lazy { init_enter_email() }

  public val feature_1_bullet_1: StringResource by 
      lazy { init_feature_1_bullet_1() }

  public val feature_1_bullet_2: StringResource by 
      lazy { init_feature_1_bullet_2() }

  public val feature_1_bullet_3: StringResource by 
      lazy { init_feature_1_bullet_3() }

  public val feature_1_subtitle: StringResource by 
      lazy { init_feature_1_subtitle() }

  public val feature_1_title: StringResource by 
      lazy { init_feature_1_title() }

  public val feature_2_bullet_1: StringResource by 
      lazy { init_feature_2_bullet_1() }

  public val feature_2_bullet_2: StringResource by 
      lazy { init_feature_2_bullet_2() }

  public val feature_2_bullet_3: StringResource by 
      lazy { init_feature_2_bullet_3() }

  public val feature_2_subtitle: StringResource by 
      lazy { init_feature_2_subtitle() }

  public val feature_2_title: StringResource by 
      lazy { init_feature_2_title() }

  public val feature_3_bullet_1: StringResource by 
      lazy { init_feature_3_bullet_1() }

  public val feature_3_bullet_2: StringResource by 
      lazy { init_feature_3_bullet_2() }

  public val feature_3_bullet_3: StringResource by 
      lazy { init_feature_3_bullet_3() }

  public val feature_3_subtitle: StringResource by 
      lazy { init_feature_3_subtitle() }

  public val feature_3_title: StringResource by 
      lazy { init_feature_3_title() }

  public val features: StringResource by 
      lazy { init_features() }

  public val features_subtitle: StringResource by 
      lazy { init_features_subtitle() }

  public val features_title: StringResource by 
      lazy { init_features_title() }

  public val get_notified: StringResource by 
      lazy { init_get_notified() }

  public val hero_subtitle: StringResource by 
      lazy { init_hero_subtitle() }

  public val hero_title: StringResource by 
      lazy { init_hero_title() }

  public val join_progress: StringResource by 
      lazy { init_join_progress() }

  public val join_success: StringResource by 
      lazy { init_join_success() }

  public val join_waitlist: StringResource by 
      lazy { init_join_waitlist() }

  public val join_waitlist_already_exists: StringResource by 
      lazy { init_join_waitlist_already_exists() }

  public val join_waitlist_error: StringResource by 
      lazy { init_join_waitlist_error() }

  public val join_waitlist_invalid_email: StringResource by 
      lazy { init_join_waitlist_invalid_email() }

  public val join_waitlist_success: StringResource by 
      lazy { init_join_waitlist_success() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("copyright", CommonMainString0.copyright)
  map.put("cta_subtitle", CommonMainString0.cta_subtitle)
  map.put("cta_title", CommonMainString0.cta_title)
  map.put("enter_email", CommonMainString0.enter_email)
  map.put("feature_1_bullet_1", CommonMainString0.feature_1_bullet_1)
  map.put("feature_1_bullet_2", CommonMainString0.feature_1_bullet_2)
  map.put("feature_1_bullet_3", CommonMainString0.feature_1_bullet_3)
  map.put("feature_1_subtitle", CommonMainString0.feature_1_subtitle)
  map.put("feature_1_title", CommonMainString0.feature_1_title)
  map.put("feature_2_bullet_1", CommonMainString0.feature_2_bullet_1)
  map.put("feature_2_bullet_2", CommonMainString0.feature_2_bullet_2)
  map.put("feature_2_bullet_3", CommonMainString0.feature_2_bullet_3)
  map.put("feature_2_subtitle", CommonMainString0.feature_2_subtitle)
  map.put("feature_2_title", CommonMainString0.feature_2_title)
  map.put("feature_3_bullet_1", CommonMainString0.feature_3_bullet_1)
  map.put("feature_3_bullet_2", CommonMainString0.feature_3_bullet_2)
  map.put("feature_3_bullet_3", CommonMainString0.feature_3_bullet_3)
  map.put("feature_3_subtitle", CommonMainString0.feature_3_subtitle)
  map.put("feature_3_title", CommonMainString0.feature_3_title)
  map.put("features", CommonMainString0.features)
  map.put("features_subtitle", CommonMainString0.features_subtitle)
  map.put("features_title", CommonMainString0.features_title)
  map.put("get_notified", CommonMainString0.get_notified)
  map.put("hero_subtitle", CommonMainString0.hero_subtitle)
  map.put("hero_title", CommonMainString0.hero_title)
  map.put("join_progress", CommonMainString0.join_progress)
  map.put("join_success", CommonMainString0.join_success)
  map.put("join_waitlist", CommonMainString0.join_waitlist)
  map.put("join_waitlist_already_exists", CommonMainString0.join_waitlist_already_exists)
  map.put("join_waitlist_error", CommonMainString0.join_waitlist_error)
  map.put("join_waitlist_invalid_email", CommonMainString0.join_waitlist_invalid_email)
  map.put("join_waitlist_success", CommonMainString0.join_waitlist_success)
}

internal val Res.string.copyright: StringResource
  get() = CommonMainString0.copyright

private fun init_copyright(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:copyright", "copyright",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    10, 85),
    )
)

internal val Res.string.cta_subtitle: StringResource
  get() = CommonMainString0.cta_subtitle

private fun init_cta_subtitle(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cta_subtitle", "cta_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    96, 88),
    )
)

internal val Res.string.cta_title: StringResource
  get() = CommonMainString0.cta_title

private fun init_cta_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cta_title", "cta_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    185, 61),
    )
)

internal val Res.string.enter_email: StringResource
  get() = CommonMainString0.enter_email

private fun init_enter_email(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_email", "enter_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    247, 43),
    )
)

internal val Res.string.feature_1_bullet_1: StringResource
  get() = CommonMainString0.feature_1_bullet_1

private fun init_feature_1_bullet_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_1_bullet_1", "feature_1_bullet_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    291, 78),
    )
)

internal val Res.string.feature_1_bullet_2: StringResource
  get() = CommonMainString0.feature_1_bullet_2

private fun init_feature_1_bullet_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_1_bullet_2", "feature_1_bullet_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    370, 86),
    )
)

internal val Res.string.feature_1_bullet_3: StringResource
  get() = CommonMainString0.feature_1_bullet_3

private fun init_feature_1_bullet_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_1_bullet_3", "feature_1_bullet_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    457, 98),
    )
)

internal val Res.string.feature_1_subtitle: StringResource
  get() = CommonMainString0.feature_1_subtitle

private fun init_feature_1_subtitle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_1_subtitle", "feature_1_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    556, 70),
    )
)

internal val Res.string.feature_1_title: StringResource
  get() = CommonMainString0.feature_1_title

private fun init_feature_1_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:feature_1_title", "feature_1_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    627, 43),
    )
)

internal val Res.string.feature_2_bullet_1: StringResource
  get() = CommonMainString0.feature_2_bullet_1

private fun init_feature_2_bullet_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_2_bullet_1", "feature_2_bullet_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    671, 94),
    )
)

internal val Res.string.feature_2_bullet_2: StringResource
  get() = CommonMainString0.feature_2_bullet_2

private fun init_feature_2_bullet_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_2_bullet_2", "feature_2_bullet_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    766, 70),
    )
)

internal val Res.string.feature_2_bullet_3: StringResource
  get() = CommonMainString0.feature_2_bullet_3

private fun init_feature_2_bullet_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_2_bullet_3", "feature_2_bullet_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    837, 58),
    )
)

internal val Res.string.feature_2_subtitle: StringResource
  get() = CommonMainString0.feature_2_subtitle

private fun init_feature_2_subtitle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_2_subtitle", "feature_2_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    896, 98),
    )
)

internal val Res.string.feature_2_title: StringResource
  get() = CommonMainString0.feature_2_title

private fun init_feature_2_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:feature_2_title", "feature_2_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    995, 51),
    )
)

internal val Res.string.feature_3_bullet_1: StringResource
  get() = CommonMainString0.feature_3_bullet_1

private fun init_feature_3_bullet_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_3_bullet_1", "feature_3_bullet_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1047, 50),
    )
)

internal val Res.string.feature_3_bullet_2: StringResource
  get() = CommonMainString0.feature_3_bullet_2

private fun init_feature_3_bullet_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_3_bullet_2", "feature_3_bullet_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1098, 62),
    )
)

internal val Res.string.feature_3_bullet_3: StringResource
  get() = CommonMainString0.feature_3_bullet_3

private fun init_feature_3_bullet_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_3_bullet_3", "feature_3_bullet_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1161, 70),
    )
)

internal val Res.string.feature_3_subtitle: StringResource
  get() = CommonMainString0.feature_3_subtitle

private fun init_feature_3_subtitle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:feature_3_subtitle", "feature_3_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1232, 138),
    )
)

internal val Res.string.feature_3_title: StringResource
  get() = CommonMainString0.feature_3_title

private fun init_feature_3_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:feature_3_title", "feature_3_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1371, 55),
    )
)

internal val Res.string.features: StringResource
  get() = CommonMainString0.features

private fun init_features(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:features", "features",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1736, 28),
    )
)

internal val Res.string.features_subtitle: StringResource
  get() = CommonMainString0.features_subtitle

private fun init_features_subtitle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:features_subtitle", "features_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1427, 209),
    )
)

internal val Res.string.features_title: StringResource
  get() = CommonMainString0.features_title

private fun init_features_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:features_title", "features_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1637, 98),
    )
)

internal val Res.string.get_notified: StringResource
  get() = CommonMainString0.get_notified

private fun init_get_notified(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:get_notified", "get_notified",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1765, 68),
    )
)

internal val Res.string.hero_subtitle: StringResource
  get() = CommonMainString0.hero_subtitle

private fun init_hero_subtitle(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hero_subtitle", "hero_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    1834, 189),
    )
)

internal val Res.string.hero_title: StringResource
  get() = CommonMainString0.hero_title

private fun init_hero_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hero_title", "hero_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    2024, 54),
    )
)

internal val Res.string.join_progress: StringResource
  get() = CommonMainString0.join_progress

private fun init_join_progress(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_progress", "join_progress",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    2079, 33),
    )
)

internal val Res.string.join_success: StringResource
  get() = CommonMainString0.join_success

private fun init_join_success(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_success", "join_success",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    2113, 28),
    )
)

internal val Res.string.join_waitlist: StringResource
  get() = CommonMainString0.join_waitlist

private fun init_join_waitlist(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_waitlist", "join_waitlist",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    2501, 41),
    )
)

internal val Res.string.join_waitlist_already_exists: StringResource
  get() = CommonMainString0.join_waitlist_already_exists

private fun init_join_waitlist_already_exists(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_waitlist_already_exists", "join_waitlist_already_exists",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    2142, 104),
    )
)

internal val Res.string.join_waitlist_error: StringResource
  get() = CommonMainString0.join_waitlist_error

private fun init_join_waitlist_error(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_waitlist_error", "join_waitlist_error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    2247, 83),
    )
)

internal val Res.string.join_waitlist_invalid_email: StringResource
  get() = CommonMainString0.join_waitlist_invalid_email

private fun init_join_waitlist_invalid_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_waitlist_invalid_email", "join_waitlist_invalid_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    2331, 83),
    )
)

internal val Res.string.join_waitlist_success: StringResource
  get() = CommonMainString0.join_waitlist_success

private fun init_join_waitlist_success(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_waitlist_success", "join_waitlist_success",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.utils.generated.resources/values/strings.commonMain.cvr",
    2415, 85),
    )
)
