package ui.mediumCompact

import androidx.compose.foundation.layout.Column
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import container
import theme.UntitledTheme

@Composable
fun MediumCompact(onScrollAndFocusEmailInput: () -> Unit, emailInputFocusRequester: FocusRequester) {
    val showInputs = false
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        Hero(emailInputFocusRequester, showInputs)
        Divider(modifier = Modifier.container(), color = UntitledTheme.colors.borderSecondary)
        Features()
        Divider(modifier = Modifier.container(), color = UntitledTheme.colors.borderSecondary)
        if (showInputs) {
            CTA(onScrollAndFocusEmailInput)
            Divider(modifier = Modifier.container(), color = UntitledTheme.colors.borderSecondary)
        }
        Footer()
    }
}