package ui.expanded

import StringsProvider
import androidx.compose.foundation.layout.padding
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import theme.UntitledTheme

@Composable
fun Footer() {
    Text(
        modifier = Modifier.padding(vertical = UntitledTheme.spacings.spacing6Xl),
        text = StringsProvider.strings.copyright,
        style = UntitledTheme.typography.textMdRegular,
        color = UntitledTheme.colors.textQuaternary
    )
}