package ui.mediumCompact

import StringsProvider
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import components.Button
import components.ButtonSize
import components.InputField
import container
import di.landingPageComponent
import frackerlandingpage.features.landingpage.generated.resources.Res
import frackerlandingpage.features.landingpage.generated.resources.logo
import frackerlandingpage.features.landingpage.generated.resources.tracker
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.vectorResource
import theme.UntitledTheme
import ui.JoinWaitlistError
import untitled_ui_components.*
import untitled_ui_components.Res as UntitledRes

@Composable
fun Hero(emailInputFocusRequester: FocusRequester, showInputs: Boolean) {
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        Header()
        Card(emailInputFocusRequester, showInputs)
    }
}

@Composable
private fun Header() {
    Box(Modifier.height(72.dp).container(), contentAlignment = Alignment.CenterStart) {
        Image(vectorResource(Res.drawable.logo), contentDescription = null)
    }
}

@Composable
private fun Card(emailInputFocusRequester: FocusRequester, showInputs: Boolean) {
    val viewModel = remember { landingPageComponent.heroViewModel }
    val state by viewModel.stateFlow.collectAsState()
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        Box(
            Modifier.background(UntitledTheme.colors.bgBrandSection).fillMaxWidth(), contentAlignment = Alignment.Center
        ) {
            Column(
                Modifier.container()
                    .padding(top = UntitledTheme.spacings.spacing7Xl, bottom = UntitledTheme.spacings.spacing9Xl),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text(
                    StringsProvider.strings.heroTitle,
                    style = UntitledTheme.typography.displayMdSemiBold,
                    color = UntitledTheme.colors.textPrimaryOnBrand,
                    textAlign = TextAlign.Center
                )
                Spacer(Modifier.height(UntitledTheme.spacings.spacingXl))
                Text(
                    StringsProvider.strings.heroSubtitle,
                    style = UntitledTheme.typography.textLgRegular,
                    color = UntitledTheme.colors.textTertiaryOnBrand,
                    textAlign = TextAlign.Center
                )
                if(showInputs) {
                    Spacer(Modifier.height(UntitledTheme.spacings.spacing5Xl))
                    InputField(
                        modifier = Modifier.fillMaxWidth().focusRequester(emailInputFocusRequester),
                        value = state.email,
                        onValueChange = viewModel::setEmail,
                        hint = when {
                            state.success -> StringsProvider.strings.joinWaitlistSuccess
                            state.error == JoinWaitlistError.Generic -> StringsProvider.strings.joinWaitlistError
                            state.error == JoinWaitlistError.AlreadyJoined -> StringsProvider.strings.joinWaitlistAlreadyExists
                            state.error == JoinWaitlistError.EmailInvalid -> StringsProvider.strings.joinWaitlistInvalidEmail
                            else -> StringsProvider.strings.getNotified
                        },
                        onEnter = viewModel::send,
                        destructive = state.error != null,
                        placeholder = StringsProvider.strings.enterEmail,
                        enabled = state.loading.not() && state.success.not()
                    )
                    Spacer(Modifier.height(UntitledTheme.spacings.spacingXl))
                    Button(
                        modifier = Modifier.fillMaxWidth(),
                        onClick = viewModel::send,
                        text = when {
                            state.loading -> StringsProvider.strings.joinProgress
                            state.success -> StringsProvider.strings.joinSuccess
                            else -> StringsProvider.strings.joinWaitlist
                        },
                        size = ButtonSize.LG,
                        iconLeft = painterResource(UntitledRes.drawable.check_circle_broken).takeIf { state.success },
                        enabled = state.loading.not() && state.success.not()
                    )
                }
            }
        }
        val borderWidth = 6.dp
        Image(
            painter = painterResource(Res.drawable.tracker),
            contentScale = ContentScale.FillWidth,
            modifier = Modifier.offset(y = -(UntitledTheme.spacings.spacing4Xl + borderWidth)).container(-borderWidth)
                .border(
                    width = borderWidth,
                    color = UntitledTheme.colors.white.copy(alpha = 0.5f),
                    shape = RoundedCornerShape(size = UntitledTheme.radius.radiusXs + borderWidth)
                ).padding(borderWidth).clip(RoundedCornerShape(size = UntitledTheme.radius.radiusXs)),
            contentDescription = null
        )
        Spacer(Modifier.height(UntitledTheme.spacings.spacing4Xl))
    }

}