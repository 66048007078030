package ui.expanded

import StringsProvider
import androidx.compose.foundation.Image
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import components.FeaturedIcon
import components.FeaturedIconSize
import frackerlandingpage.features.landingpage.generated.resources.Res
import frackerlandingpage.features.landingpage.generated.resources.analytics
import frackerlandingpage.features.landingpage.generated.resources.timesheet
import frackerlandingpage.features.landingpage.generated.resources.tracker
import org.jetbrains.compose.resources.painterResource
import theme.UntitledTheme
import untitled_ui_components.bar_chart_01
import untitled_ui_components.check_circle
import untitled_ui_components.clock_stopwatch
import untitled_ui_components.file_check_02
import untitled_ui_components.Res as UntitledRes

@Composable
fun Features() {
    Column(
        Modifier.padding(vertical = UntitledTheme.spacings.spacing9Xl),
        verticalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacing9Xl),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Header()
        FeatureSection(
            icon = painterResource(UntitledRes.drawable.clock_stopwatch),
            title = StringsProvider.strings.feature1Title,
            subtitle = StringsProvider.strings.feature1Subtitle,
            features = listOf(
                StringsProvider.strings.feature1Bullet1,
                StringsProvider.strings.feature1Bullet2,
                StringsProvider.strings.feature1Bullet3
            ),
            image = painterResource(Res.drawable.tracker)
        )
        FeatureSection(
            icon = painterResource(UntitledRes.drawable.bar_chart_01),
            title = StringsProvider.strings.feature2Title,
            subtitle = StringsProvider.strings.feature2Subtitle,
            features = listOf(
                StringsProvider.strings.feature2Bullet1,
                StringsProvider.strings.feature2Bullet2,
                StringsProvider.strings.feature2Bullet3
            ),
            image = painterResource(Res.drawable.analytics),
            flip = true
        )
        FeatureSection(
            icon = painterResource(UntitledRes.drawable.file_check_02),
            title = StringsProvider.strings.feature3Title,
            subtitle = StringsProvider.strings.feature3Subtitle,
            features = listOf(
                StringsProvider.strings.feature3Bullet1,
                StringsProvider.strings.feature3Bullet2,
                StringsProvider.strings.feature3Bullet3
            ),
            image = painterResource(Res.drawable.timesheet)
        )
    }
}

@Composable
private fun Header() {
    Column(Modifier.widthIn(max = UntitledTheme.widths.widthXl), horizontalAlignment = Alignment.CenterHorizontally) {
        Text(
            StringsProvider.strings.features,
            style = UntitledTheme.typography.textMdSemiBold,
            color = UntitledTheme.colors.textBrandSecondary700,
            textAlign = TextAlign.Center
        )
        Spacer(Modifier.height(UntitledTheme.spacings.spacingLg))
        Text(
            StringsProvider.strings.featuresTitle,
            style = UntitledTheme.typography.displayMdSemiBold,
            color = UntitledTheme.colors.textPrimary900,
            textAlign = TextAlign.Center
        )
        Spacer(Modifier.height(UntitledTheme.spacings.spacing2Xl))
        Text(
            StringsProvider.strings.featuresSubtitle,
            style = UntitledTheme.typography.textXlRegular,
            color = UntitledTheme.colors.textTertiary600,
            textAlign = TextAlign.Center
        )
    }
}

@Composable
private fun FeatureSection(
    icon: Painter, title: String, subtitle: String, features: List<String>, image: Painter, flip: Boolean = false
) {
    val height = 512.dp
    val content = listOf<@Composable RowScope.() -> Unit>({
        Column(Modifier.weight(1f)) {
            FeaturedIcon(
                icon, size = FeaturedIconSize.LG
            )
            Spacer(Modifier.height(UntitledTheme.spacings.spacing2Xl))
            Text(
                title, style = UntitledTheme.typography.displaySmSemiBold, color = UntitledTheme.colors.textPrimary900
            )
            Spacer(Modifier.height(UntitledTheme.spacings.spacingXl))
            Text(
                subtitle, style = UntitledTheme.typography.textLgRegular, color = UntitledTheme.colors.textTertiary600
            )
            Spacer(Modifier.height(UntitledTheme.spacings.spacing4Xl))
            FeatureList(features)
        }
    }, {
        val borderWidth = 4.dp
        Image(
            painter = image,
            contentScale = ContentScale.FillHeight,
            modifier = Modifier.weight(1f)
                .wrapContentWidth(if (flip) Alignment.End else Alignment.Start, unbounded = true)
                .border(
                    width = borderWidth,
                    color = UntitledTheme.colors.screenMockupBorder,
                    shape = RoundedCornerShape(size = UntitledTheme.radius.radiusLg + borderWidth)
                ).padding(borderWidth).height(height)
                .clip(RoundedCornerShape(size = UntitledTheme.radius.radiusLg)),
            contentDescription = null
        )
    })
    Row(
        Modifier.fillMaxWidth().height(height),
        horizontalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacing9Xl),
        verticalAlignment = Alignment.CenterVertically
    ) {
        if (flip) {
            content.reversed().forEach { it() }
        } else {
            content.forEach { it() }
        }
    }
}

@Composable
private fun FeatureList(features: List<String>) {
    Column(
        Modifier.padding(start = UntitledTheme.spacings.spacingXl),
        verticalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacing2Xl)
    ) {
        features.forEach { FeatureItem(it) }
    }
}

@Composable
private fun FeatureItem(text: String) {
    Row(
        horizontalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacingLg),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            modifier = Modifier.size(28.dp),
            painter = painterResource(UntitledRes.drawable.check_circle),
            tint = UntitledTheme.colors.fgBrandPrimary600,
            contentDescription = null
        )
        Text(
            text, style = UntitledTheme.typography.textLgRegular, color = UntitledTheme.colors.textTertiary600
        )
    }
}