package ui.mediumCompact

import StringsProvider
import androidx.compose.foundation.Image
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import components.FeaturedIcon
import components.FeaturedIconSize
import container
import frackerlandingpage.features.landingpage.generated.resources.Res
import frackerlandingpage.features.landingpage.generated.resources.analytics
import frackerlandingpage.features.landingpage.generated.resources.timesheet
import frackerlandingpage.features.landingpage.generated.resources.tracker
import org.jetbrains.compose.resources.painterResource
import theme.UntitledTheme
import untitled_ui_components.bar_chart_01
import untitled_ui_components.check_circle
import untitled_ui_components.clock_stopwatch
import untitled_ui_components.file_check_02
import untitled_ui_components.Res as UntitledRes

@Composable
fun Features() {
    Column(
        Modifier.padding(vertical = UntitledTheme.spacings.spacing7Xl),
        verticalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacing6Xl),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Header()
        FeatureSection(
            painterResource(UntitledRes.drawable.clock_stopwatch),
            StringsProvider.strings.feature1Title, StringsProvider.strings.feature1Subtitle, listOf(
                StringsProvider.strings.feature1Bullet1,
                StringsProvider.strings.feature1Bullet2,
                StringsProvider.strings.feature1Bullet3
            ), painterResource(Res.drawable.tracker)
        )
        FeatureSection(
            painterResource(UntitledRes.drawable.bar_chart_01),
            StringsProvider.strings.feature2Title, StringsProvider.strings.feature2Subtitle, listOf(
                StringsProvider.strings.feature2Bullet1,
                StringsProvider.strings.feature2Bullet2,
                StringsProvider.strings.feature2Bullet3
            ), painterResource(Res.drawable.analytics)
        )
        FeatureSection(
            painterResource(UntitledRes.drawable.file_check_02),
            StringsProvider.strings.feature3Title, StringsProvider.strings.feature3Subtitle, listOf(
                StringsProvider.strings.feature3Bullet1,
                StringsProvider.strings.feature3Bullet2,
                StringsProvider.strings.feature3Bullet3
            ), painterResource(Res.drawable.timesheet)
        )
    }
}

@Composable
private fun Header() {
    Column(Modifier.container(), horizontalAlignment = Alignment.CenterHorizontally) {
        Text(
            StringsProvider.strings.features,
            style = UntitledTheme.typography.textSmSemiBold,
            color = UntitledTheme.colors.textBrandSecondary700,
            textAlign = TextAlign.Center
        )
        Spacer(Modifier.height(UntitledTheme.spacings.spacingLg))
        Text(
            StringsProvider.strings.featuresTitle,
            style = UntitledTheme.typography.displaySmSemiBold,
            color = UntitledTheme.colors.textPrimary900,
            textAlign = TextAlign.Center
        )
        Spacer(Modifier.height(UntitledTheme.spacings.spacingXl))
        Text(
            StringsProvider.strings.featuresSubtitle,
            style = UntitledTheme.typography.textLgRegular,
            color = UntitledTheme.colors.textTertiary600,
            textAlign = TextAlign.Center
        )
    }
}

@Composable
private fun FeatureSection(
    icon: Painter, title: String, subtitle: String, features: List<String>, image: Painter
) {
    Column {
        Column(Modifier.container()) {
            FeaturedIcon(icon, size = FeaturedIconSize.LG)
            Spacer(Modifier.height(UntitledTheme.spacings.spacing2Xl))
            Text(
                title, style = UntitledTheme.typography.displayXsSemiBold, color = UntitledTheme.colors.textPrimary900
            )
            Spacer(Modifier.height(UntitledTheme.spacings.spacingMd))
            Text(
                subtitle, style = UntitledTheme.typography.textMdRegular, color = UntitledTheme.colors.textTertiary600
            )
            Spacer(Modifier.height(UntitledTheme.spacings.spacing4Xl))
            FeatureList(features)
        }
        Spacer(Modifier.height(UntitledTheme.spacings.spacing5Xl))
        val borderWidth = 4.dp
        Image(
            painter = image,
            contentScale = ContentScale.FillWidth,
            modifier = Modifier.container(-borderWidth).border(
                width = borderWidth,
                color = UntitledTheme.colors.screenMockupBorder,
                shape = RoundedCornerShape(size = UntitledTheme.radius.radiusXs + borderWidth)
            ).padding(borderWidth).clip(RoundedCornerShape(size = UntitledTheme.radius.radiusXs)),
            contentDescription = null
        )
    }
}

@Composable
private fun FeatureList(features: List<String>) {
    Column(
        Modifier.padding(start = UntitledTheme.spacings.spacingMd),
        verticalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacingXl)
    ) {
        features.forEach { FeatureItem(it) }
    }
}

@Composable
private fun FeatureItem(text: String) {
    Row(
        horizontalArrangement = Arrangement.spacedBy(UntitledTheme.spacings.spacingLg),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            modifier = Modifier.size(28.dp),
            painter = painterResource(UntitledRes.drawable.check_circle),
            tint = UntitledTheme.colors.fgBrandPrimary600,
            contentDescription = null
        )
        Text(text, style = UntitledTheme.typography.textMdRegular, color = UntitledTheme.colors.textTertiary600)
    }
}