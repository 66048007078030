package data

import IFirestoreClient
import data.models.WaitlistEntry
import di.Singleton
import me.tatarka.inject.annotations.Inject
import kotlin.uuid.ExperimentalUuidApi
import kotlin.uuid.Uuid

interface IWaitlistRepository {

    suspend fun add(waitlistEntry: WaitlistEntry): Result<Unit>

    suspend fun get(): Result<List<WaitlistEntry>>

}

@Singleton
@Inject
class WaitlistRepository(private val firestoreClient: IFirestoreClient) : IWaitlistRepository {

    @OptIn(ExperimentalUuidApi::class)
    override suspend fun add(waitlistEntry: WaitlistEntry): Result<Unit> = runCatching {
        firestoreClient.setDocument("waitlist/${Uuid.random()}", waitlistEntry, WaitlistEntry.serializer())
    }

    override suspend fun get(): Result<List<WaitlistEntry>> = runCatching {
        firestoreClient.getCollection("waitlist", WaitlistEntry.serializer())
    }

}