@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package frackerlandingpage.features.landingpage.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val analytics: DrawableResource by 
      lazy { init_analytics() }

  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val timesheet: DrawableResource by 
      lazy { init_timesheet() }

  public val tracker: DrawableResource by 
      lazy { init_tracker() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("analytics", CommonMainDrawable0.analytics)
  map.put("logo", CommonMainDrawable0.logo)
  map.put("timesheet", CommonMainDrawable0.timesheet)
  map.put("tracker", CommonMainDrawable0.tracker)
}

internal val Res.drawable.analytics: DrawableResource
  get() = CommonMainDrawable0.analytics

private fun init_analytics(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:analytics",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.features.landingpage.generated.resources/drawable/analytics.png", -1, -1),
    )
)

internal val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.features.landingpage.generated.resources/drawable/logo.xml", -1, -1),
    )
)

internal val Res.drawable.timesheet: DrawableResource
  get() = CommonMainDrawable0.timesheet

private fun init_timesheet(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:timesheet",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.features.landingpage.generated.resources/drawable/timesheet.png", -1, -1),
    )
)

internal val Res.drawable.tracker: DrawableResource
  get() = CommonMainDrawable0.tracker

private fun init_tracker(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:tracker",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/frackerlandingpage.features.landingpage.generated.resources/drawable/tracker.png", -1, -1),
    )
)
