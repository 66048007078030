package di

import IFirestoreClient
import data.IWaitlistRepository
import data.WaitlistRepository
import domain.usecases.CreateWaitlistEntryNow
import domain.usecases.ICreateWaitlistEntryNow
import firestoreClient
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.datetime.Clock
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.KmpComponentCreate
import me.tatarka.inject.annotations.Provides
import me.tatarka.inject.annotations.Scope
import ui.HeroViewModel
import ui.IHeroViewModel
import kotlin.annotation.AnnotationTarget.*

value class ViewModelScope(private val value: CoroutineScope) : CoroutineScope by value

@KmpComponentCreate
internal expect fun createLandingPageComponent(): LandingPageComponent

val landingPageComponent by lazy { createLandingPageComponent() }

@Scope
@Target(CLASS, FUNCTION, PROPERTY_GETTER)
annotation class Singleton

@Singleton
@Component
abstract class LandingPageComponent {

    abstract val heroViewModel: IHeroViewModel

    @Singleton
    @Provides
    protected fun viewModelScope(): ViewModelScope = ViewModelScope(CoroutineScope(Dispatchers.Main.immediate))

    @Singleton
    @Provides
    protected fun jsonParser(): IFirestoreClient = firestoreClient()

    @Singleton
    @Provides
    protected fun clock(): Clock = Clock.System

    protected val WaitlistRepository.bind: IWaitlistRepository
        @Provides get() = this

    protected val CreateWaitlistEntryNow.bind: ICreateWaitlistEntryNow
        @Provides get() = this

    protected val HeroViewModel.bind: IHeroViewModel
        @Provides get() = this

}